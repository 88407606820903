<!--
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-09 10:16:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-06-25 18:53:28
-->
<template>
  <div class="goods">
    <div class="history-wrapper">
      <div class="label">当前搜索结果：</div>
      <div class="history">
        <!-- 分类 -->
        <CustomTag
          v-if="currSelectedTree.first"
          :value="currSelectedTree.first.text"
          @close="onTreeClose('first')"
        ></CustomTag>
        <template v-if="currSelectedTree.second">
          <span style="margin-right: 8px">></span>
          <CustomTag
            :value="currSelectedTree.second.text"
            @close="onTreeClose('second')"
          ></CustomTag>
        </template>
        <template v-if="currSelectedTree.third">
          <span style="margin-right: 8px">></span>
          <CustomTag
            :value="currSelectedTree.third.text"
            @close="onTreeClose('third')"
          ></CustomTag>
        </template>
        <!-- 其它筛选 -->
        <CustomTag
          v-for="(item, index) in selectedCategory"
          :key="index"
          :value="item.name"
          @close="onClose(item, index)"
        ></CustomTag>
      </div>
      <div
        v-if="
          selectedCategory.length ||
            currSelectedTree.first ||
            currSelectedTree.second ||
            currSelectedTree.third
        "
        class="clear-box cursor"
        @click="handleClearSelect"
      >
        清空所有筛选项
      </div>
    </div>
    <div class="content-wrapper">
      <div class="category">
        <div class="name">
          <span>分类</span>
        </div>
        <div class="content">
          <div class="category-header">
            <div
              class="category-child"
              :class="expandDosageForm ? '' : 'hidden'"
            >
              <div
                v-for="(item, index) in productTree"
                :key="index"
                @click="handTreeClick(item, 1)"
                class="item mr30 hover cursor"
                :class="item.checked ? 'selected' : ''"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="category" v-if="secondTree.length > 0">
        <div class="name">
          <span>二级分类</span>
        </div>
        <div class="content">
          <div class="category-header">
            <div
              class="category-child"
              :class="expandDosageForm ? '' : 'hidden'"
            >
              <div
                v-for="(item, index) in secondTree"
                :key="index"
                @click="handTreeClick(item, 2)"
                class="item mr30 hover cursor"
                :class="item.checked ? 'selected' : ''"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="category" v-if="thirdTree.length > 0">
        <div class="name">
          <span>三级分类</span>
        </div>
        <div class="content">
          <div class="category-header">
            <div
              class="category-child"
              :class="expandDosageForm ? '' : 'hidden'"
            >
              <div
                v-for="(item, index) in thirdTree"
                :key="index"
                @click="handTreeClick(item, 3)"
                class="item mr30 hover cursor"
                :class="item.checked ? 'selected' : ''"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 品牌 -->
      <div v-if="pingpai.length > 0" class="category">
        <div class="name">
          <span>品牌</span>
        </div>
        <div class="content">
          <div class="category-header">
            <div class="category-child" :class="brandForm ? '' : 'hidden'">
              <div
                v-for="(item, index) in pingpai"
                :key="index"
                @click="handleSelectCategoryChild(item)"
                class="item mr30 hover cursor"
                :class="judgeSelected(item) ? 'selected' : ''"
              >
                {{ item.name }}
              </div>
            </div>
            <div
              @click="brandForm = !brandForm"
              class="action-box hover cursor"
              :class="brandForm ? 'closed' : ''"
            >
              <span class="mr8">{{ brandForm ? "收起" : "展开" }}</span>
              <i
                :class="brandForm ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="category">
        <div class="name">
          <span>剂型</span>
        </div>
        <div class="content">
          <div class="category-header">
            <div
              class="category-child"
              :class="expandDosageForm ? '' : 'hidden'"
            >
              <div
                v-for="(item, index) in medicalTypes"
                :key="index"
                @click="handleSelectCategoryChild(item)"
                class="item mr30 hover cursor"
                :class="judgeSelected(item) ? 'selected' : ''"
              >
                {{ item.name }}
              </div>
            </div>
            <div
              @click="expandDosageForm = !expandDosageForm"
              class="action-box hover cursor"
              :class="expandDosageForm ? 'closed' : ''"
            >
              <span class="mr8">{{ expandDosageForm ? "收起" : "展开" }}</span>
              <i
                :class="
                  expandDosageForm ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="category">
        <div class="name">
          <span>单位</span>
        </div>
        <div class="content">
          <div class="category-child">
            <div
              v-for="(item, index) in units"
              :key="index"
              @click="handleSelectCategoryChild(item)"
              class="item mr30 hover cursor"
              :class="judgeSelected(item) ? 'selected' : ''"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Rank
      @on-sort-change="onSortChange"
      @on-stock-change="onStockChange"
      :total="total"
      :pageSize="pageNum"
      :closestTimeFlag="closestTimeFlag"
      @on-preview="onSortPageChange(-1)"
      @on-next="onSortPageChange(1)"
    ></Rank>
    <ul class="recommend-list" v-if="goodsList.length">
      <li v-for="(item, index) in goodsList" :key="index" class="item">
        <GoodsCard
          :pageNum="pageNum"
          :keyword="keyword"
          :closestTimeFlag="closestTimeFlag"
          :value="item"
        >
        </GoodsCard>
        <div class="handle-box">
          <div class="stepper-box">
            <CustomInputNumber
              :min="item.minSales"
              :step="item.minSales"
              :stepStrictly="true"
              :max="
                item.pchao
                  ? item.erpStockNum
                  : item.shopType === 1
                  ? Infinity
                  : item.erpStockNum
              "
              v-model="item.num"
            ></CustomInputNumber>
          </div>
          <div @click="addCart(item)" class="cart-box cup">
            <img
              class="icon"
              style="vertical-align: top;
                  margin-right: 5px;
                  width: 14px;
                  height: 14px;
                "
              src="@/assets/images/goodsCart.png"
              alt
            />
            加入购物车
          </div>
        </div>
      </li>
    </ul>
    <el-empty v-else></el-empty>
    <div v-if="goodsList.length" class="pagination flex-end">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="pageNum"
        :page-size="20"
        layout="prev, pager, next, total,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <div class="person">
      <el-dialog
        class="flex-center"
        :close-on-click-modal="false"
        :visible.sync="personDialog"
        width="260px"
      >
        <Person :isgoods="true" @claosePerson="claosePerson" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Person from "@/components/custom-base/custom-person";
import Breadcrumb from "../../components/Breadcrumb.vue";
import { findProductList } from "@/request/CartApi/cart";
import GoodsCard from "./components/goods-card.vue";
import Rank from "./components/rank.vue";
import { categorysTree } from "@/request/api";
import CustomTag from "@/components/custom-base/custom-tag";
import CustomInputNumber from "@/components/custom-base/custom-input-number/index.vue";
import { CATEGORY_WORDS, CATEGORY_KEY } from "@/utils/constant";
import {
  productsDetail,
  brandmanages,
  addCartApi,
  searchconditions,
  searchconditionsMedical,
  searchProducts,
} from "@/request/goods/good";
import { log } from "mathjs";
import {throttle} from 'lodash'
export default {
  components: {
    Person,
    Breadcrumb,
    GoodsCard,
    Rank,
    CustomTag,
    CustomInputNumber,
  },
  data() {
    return {
      activityType: null, //1活动专区,0非活动专区
      personDialog: false,
      productTree: [],
      shopType: "",
      loans: null,
      closestTimeFlag: 0,
      erpCategoryScope: "",
      CATEGORY_WORDS,
      medicalTypes: [],
      pingpai: [
        {
          name: "哈药集团",
        },
        {
          name: "明欣药业",
        },
        {
          name: "紫竹药业",
        },
        {
          name: "中新药业",
        },
        {
          name: "其他",
        },
      ],
      units: [],
      keyword: "",
      expandCategory: true,
      expandDosageForm: true,
      brandForm: true,
      currentCategoryIndex: 0,
      selectedCategory: [],
      goodsList: [],
      categoryList: [],
      sortOptions: "sellTotal,desc",
      pageNum: 1,
      pageSize: 20,
      total: 0,
      CATEGORY_KEY: {
        UNIT: "unit",
        DOSAGE_FORM: "dosageForm",
        PINGPAI: "pingpai",
        CATEGORY: "category",
      },
      //分类功能实现
      selectedTree: [],
      productTree: [],
      secondTree: [],
      thirdTree: [],
      currSelectedTree: {
        first: "",
        second: "",
        third: "",
      },
      categoryId: "",
      brandIds: [],
      stockFlag:1,  //  库存查询
    };
  },
  created() {
    this.fetchCategoryChildren({}, 0);
    this.fetchDosageForm();
    this.categoryId = (this.$route.query && this.$route.query.id) || "";
    categorysTree().then((res) => {
      const tree = res.data[0].children;
      const item = {
        id: "",
        checked: true,
        text: "全部",
        parentId: 0,
        name: "全部",
      };
      tree.unshift(item);
      this.productTree = this.addAllSection(tree);
      //选中分类列表页面传输过来的ID
      if (
        this.$route.query &&
        this.$route.query.id &&
        this.$route.query.parentId
      ) {
        this.productTree.map((v) => {
          v.checked = false;
          if (v.children && v.children.length > 0) {
            v.children.map((cv) => {
              cv.checked = false;
              if (cv.id == this.$route.query.parentId) {
                cv.checked = true;
                v.checked = true;
                this.currSelectedTree.first = v;
                this.currSelectedTree.second = cv;
                this.secondTree = v.children;
                if (cv.children && cv.children.length > 0) {
                  cv.children.map((ccv) => {
                    ccv.checked = false;
                    if (ccv.id == this.$route.query.id) {
                      ccv.checked = true;
                      this.currSelectedTree.third = ccv;
                      this.thirdTree = cv.children;
                    }
                  });
                }
              }
            });
          }
        });
        this.fetchGoods();
        this.$forceUpdate();
      }
    });
  },
  // mounted() {
  //   this.fetchGoods();
  // },
  beforeDestroy() {
    this.activityType = null;
    this.closestTimeFlag = null;
    this.shopType = null;
    this.erpCategoryScope = null;
    this.brandIds = null;
    this.keyword = null;
    this.categoryName = null;
    this.medicalType = null;
    this.categoryId = null;
    this.unit = null;
  },
  watch: {
    $route: {
      handler(n, o) {
        this.selectedCategory = [];
        this.productTree[0] = {
          id: "",
          checked: true,
          text: "全部",
          parentId: 0,
          name: "全部",
        };

        if (n.query.brandIds && n.query.shopType) {
          this.keyword = n.query.keyword;
          this.erpCategoryScope = "";
          this.brandIds = null;
          this.activityType = null;
          this.shopType = n.query.shopType;
          brandmanages({ pageNum: 1, pageSize: 999 }).then((res) => {
            if (res.code === 200) {
              this.pingpai = res.data.rows;
              if (n.query.brandIds == "first") {
                // this.handleSelectCategoryChild(this.pingpai[0]);
              } else {
                this.pingpai.map((item) => {
                  if (item.id == n.query.brandIds) {
                    this.handleSelectCategoryChild(item);
                  }
                });
              }
            }
          });
        } else {
          this.pingpai = [];
        }
        const keyword = n.query.keyword || "";
        const erpCategoryScope = n.query.erpCategoryScope || "";
        const shopType = n.query.shopType || "";
        const activityType = n.query.activityType || "";
        const closestTimeFlag = n.query.closestTimeFlag || 0;
        const loans = n.query.loans || null;
        console.log(loans)
        if (n.query && !n.query.parentId) {
          if (keyword) {
            this.keyword = keyword;
            this.erpCategoryScope = erpCategoryScope;
            this.shopType = n.query.shopType || "";
            this.activityType = n.query.activityType || "";
            this.closestTimeFlag = n.query.closestTimeFlag || 0;
            this.loans = n.query.loans || null;
          } else if (erpCategoryScope) {
            this.clearSearch();
            this.erpCategoryScope = erpCategoryScope;
            this.shopType = "";
            this.closestTimeFlag = 0;
            this.brandIds = null;
            this.keyword = "";
            this.activityType = null;
            this.loans = null;
          } else if (loans) {
            this.clearSearch();
            this.loans = loans;
          } else if (shopType) {
            this.keyword = "";
            this.closestTimeFlag = 0;
            this.erpCategoryScope = "";
            this.brandIds = null;
            this.activityType = null;
            this.shopType = shopType;
            this.loans = null;
          } else if (activityType) {
            this.closestTimeFlag = 0;
            this.erpCategoryScope = "";
            this.brandIds = null;
            this.shopType = "";
            this.keyword = "";
            this.activityType = activityType;
            this.loans = null;
          } else if (closestTimeFlag) {
            this.clearSearch();
            this.closestTimeFlag = closestTimeFlag;
            this.shopType = 1;
          }
          this.pageNum = 1;

          if (!keyword && localStorage.getItem("pageNum")) {
            this.pageNum = localStorage.getItem("pageNum");
            localStorage.removeItem("pageNum");
          }
          this.fetchGoods();
        }
      },
      immediate: true,
    },
    selectedCategory(n, o) {
      this.pageNum = 1;
      this.fetchGoods();
    },
  },
  methods: {
    clearSearch() {
      this.loans = null;
      this.activityType = null;
      this.erpCategoryScope = "";
      this.brandIds = null;
      this.shopType = "";
      this.keyword = "";
      this.closestTimeFlag = 0;
    },
    //增加筛选项目
    addAllSection(data) {
      if (data.length > 0) {
        data.map((v) => {
          v.name = v.text;
          if (v.children && v.children.length > 0) {
            v.children.unshift({
              id: "",
              checked: true,
              text: "全部",
              parentId: v.id,
              name: "全部",
            });
            this.addAllSection(v.children);
          }
        });
      }
      return data;
    },
    //处理分类点击事件 type 1:一级，2-二级，3-三级
    handTreeClick(item, type) {
      this.keyword = "";
      this.$store.commit("setSearchKeyWord", "");
      if (type == 1) {
        this.productTree.map((v) => (v.checked = false));
        item.checked = true;
        this.currSelectedTree.first = item.id ? item : null;
        this.currSelectedTree.second = null;
        this.currSelectedTree.third = null;
        this.secondTree = item.children || [];
        if (this.secondTree.length > 0) {
          this.secondTree.map((v) => (v.checked = false));
          this.secondTree[0].checked = true;
        }
        this.thirdTree = [];
        this.categoryId = item.id ? item.id : "";
      } else if (type == 2) {
        this.secondTree.map((v) => (v.checked = false));
        item.checked = true;
        this.currSelectedTree.second = item.id ? item : null;
        this.currSelectedTree.third = null;
        this.thirdTree = item.children || [];
        if (this.thirdTree.length > 0) {
          this.thirdTree.map((v) => (v.checked = false));
          this.thirdTree[0].checked = true;
        }
        this.categoryId = item.id
          ? item.id
          : (this.currSelectedTree.first && this.currSelectedTree.first.id) ||
            "";
      } else if (type == 3) {
        this.thirdTree.map((v) => (v.checked = false));
        item.checked = true;
        this.currSelectedTree.third = item.id ? item : null;
        this.categoryId = item.id
          ? item.id
          : (this.currSelectedTree.second && this.currSelectedTree.second.id) ||
            "";
      }
      this.fetchGoods();
    },
    //分类关闭
    onTreeClose(key) {
      this.currSelectedTree[key] = null;
      if (key == "first") {
        this.productTree.map((v) => (v.checked = false));
        if (this.productTree.length > 0) {
          this.productTree[0].checked = true;
        }
        this.categoryId = null;
        this.thirdTree = [];
        this.secondTree = [];
        this.currSelectedTree.second = null;
        this.currSelectedTree.third = null;
      } else if (key == "second") {
        this.categoryId =
          (this.currSelectedTree.first && this.currSelectedTree.first.id) || "";
        this.secondTree.map((v) => (v.checked = false));
        if (this.secondTree.length > 0) {
          this.secondTree[0].checked = true;
        }
        this.thirdTree = [];
        this.currSelectedTree.third = null;
      } else if (key == "third") {
        this.categoryId = this.currSelectedTree.second.id || "";
        this.thirdTree.map((v) => (v.checked = false));
        if (this.thirdTree.length > 0) {
          this.thirdTree[0].checked = true;
        }
      }
      this.fetchGoods();
    },
    //获取分类
    async fetchCategoryChildren(item, index) {
      const { value } = item;
      this.currentCategoryIndex = index;
      const params = {
        firstUpperLetter: value || null,
        isAsc: "asc",
        pageNum: 1,
        pageSize: 100,
      };
      const {
        code,
        data: { rows },
      } = await searchconditions(params);
      if (code === 200) {
        this.categoryList = rows.map((item) => {
          return {
            ...item,
            category: CATEGORY_KEY.CATEGORY,
          };
        });
        this.expandCategory = true;
      }
    },

    //获取商品列表
    async fetchGoods() {
      const {
        pageNum,
        pageSize,
        selectedCategory,
        sortOptions,
        brandIds,
        stockFlag
      } = this;

      const params = {
        cusUid: this.$store.state.AddrId,
        activityType: this.activityType,
        loans: this.loans,
        pageNum,
        pageSize,
        closestTimeFlag: this.closestTimeFlag,
        shopType: this.erpCategoryScope == "" ? this.shopType : "",
        erpCategoryScope: this.shopType == "" ? this.erpCategoryScope : "",
        queryFields: "name,shortName,manufacturer,zjm,snZjm",
        keyword: this.keyword,
        brandIds: this._filterGetKey(
          selectedCategory,
          "id",
          CATEGORY_KEY.PINGPAI
        )
          ? this._filterGetKey(selectedCategory, "id", CATEGORY_KEY.PINGPAI)
          : [],
        categoryName: this._filterGetKey(
          selectedCategory,
          "name",
          CATEGORY_KEY.CATEGORY
        ),
        medicalType: this._filterGetKey(
          selectedCategory,
          "name",
          CATEGORY_KEY.DOSAGE_FORM
        ),
        categoryId: this.categoryId,
        unit: this._filterGetKey(selectedCategory, "name", CATEGORY_KEY.UNIT),
        sort: sortOptions,
        stockFlag:this.closestTimeFlag == 1 ? undefined : stockFlag
      };
      let requestURL = searchProducts;
      const { code, data } = await requestURL(params);
      if (code === 200) {
        const rows = data.rows || [];
        rows.map((item) => {
          item.num = item.minSales;
          //压缩图片
          item.transImg = item.attachUrl?.split(",");
          if (item.transImg.length !== 0) {
            let urlImg = [];
            item.transImg.map((it) => {
              if (it.lastIndexOf("show") !== -1) {
                it.replace(
                  "show",
                  "showcompress?compressType=size&value=264x264"
                );
              }
            });

            item.firstImg = item.attachUrl?.split(",")?.[0] || item.attachUrl;
          }

          return item;
        });
        this.goodsList = rows;
        this.total = data.total || 0;
      }
    },
    //分类搜索
    handleSelectCategoryChild(item) {
      if (this.judgeSelected(item)) {
        const index = this.selectedCategory
          .map((item) => item.name)
          .indexOf(item.name);
        this.selectedCategory.splice(index, 1);
      } else {
        this.selectedCategory.push(item);
      }
    },
    judgeSelected(target) {
      return this.selectedCategory
        .map((item) => item.name)
        .includes(target.name);
    },

    async fetchDosageForm() {
      const {
        data: { units, medicalTypes, pingpai },
        code,
      } = await searchconditionsMedical();
      if (code === 200) {
        this.units = this._filterSetKey(units, CATEGORY_KEY.UNIT);
        this.medicalTypes = this._filterSetKey(
          medicalTypes,
          CATEGORY_KEY.DOSAGE_FORM
        );
        // this.pingpai = this._filterSetKey(pingpai, CATEGORY_KEY.PINGPAI);
      }
    },
    _filterSetKey(list, key) {
      if (list?.length) {
        return list.map((item) => {
          return {
            name: item,
            category: key,
            value: item,
            selected: false,
          };
        });
      } else {
        return [];
      }
    },
    _filterGetKey(list, key, category) {
      return list
        .filter((item) => {
          return item.category === category;
        })
        .map((item) => item[key])
        .join(",");
    },
    onClose(item, index) {
      this.selectedCategory.splice(index, 1);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.fetchGoods();
    },
    handleClearSelect() {
      this.selectedCategory = [];
      this.currentCategoryIndex = 0;
      this.categoryId = null;
      this.currSelectedTree = {
        first: null,
        second: null,
        third: null,
      };
      this.secondTree = [];
      this.thirdTree = [];
      this.productTree.map((v, i) => {
        v.checked = i == 0;
      });
    },
    onSortChange(value) {
      this.sortOptions = value;
      this.fetchGoods();
    },
    onStockChange(value) {
      this.stockFlag = value ? 1 : undefined;
      this.fetchGoods();
    },
    onSortPageChange(value) {
      this.pageNum = Math.max(1, this.pageNum + value);
      this.fetchGoods();
    },
    //关闭经理弹窗
    claosePerson() {
      this.personDialog = false;
    },
    throttleFn(item){
      throttle(function(){
        console.log(111)
      },2000)
    },
    //加入购物车
    async addCart(item) {
      if (!this.$store.state.AddrId) {
        this.$message.warning("请先选择收货地址");
        return;
      }

      let itemReqDTO = {
        pchao: item.shopType !== 2 && item.pchao ? item.pchao : null,
        productFromType: 0,
        customerAddrId: this.$store.state.AddrId,
        productId: item.id,
        productQuantity: item.num,
      };
      const { code, data } = await addCartApi(itemReqDTO);
      if (code === 200) {
        findProductList({
          customerId: this.$store.state.AddrId,
        }).then((res) => {
          if (res.code === 200) {
            let productNum = res.data.productList.length;
            this.$store.commit("setProductNum", productNum);
          }
        });
        this.$message({
          message: "已加入购物车",
          type: "success",
        });
      } else if (code === 10002) {
        this.personDialog = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/base.less";
::v-deep {
  .person {
    .el-dialog__header {
      display: none;
    }
    .el-dialog {
      background: none;
      box-shadow: none;
    }
  }
}
.goods {
  min-height: calc(100vh - 500px);
  padding: 24px 0;
  .history-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: #333333;
    .label {
      flex: 0 0 100px;
      margin-bottom: 6px;
      line-height: 30px;
    }
    .history {
      flex-grow: 1;
    }
    .clear-box {
      flex: 0 0 120px;
      color: @green;
      text-align: right;
    }
  }
  .content-wrapper {
    margin: 14px auto 12px;
    color: #333333;
    border: 1px solid #f5f5f5;
    font-family: 'regular';
    .category {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      border-bottom: 1px solid #f5f5f5;
      .name {
        flex: 0 0 124px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f7f8fa;
      }
      .content {
        flex-grow: 1;
        padding: 5px 10px 5px;
        text-align: left;
        .category-header {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-bottom: 0;
          .item-box {
            flex-grow: 1;
            box-sizing: border-box;
            .item {
              display: inline-block;
              margin-right: 12px;
              margin-bottom: 2px;
              padding: 4px 8px;
              cursor: pointer;
              box-sizing: border-box;
              user-select: none;
            }
            .selected {
              border: 1px solid @green;
              color: @green;
            }
          }
          .action-box {
            flex: 0 0 80px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
          }
          .closed {
            color: #26c487;
          }
        }
        .category-child {
          transition: all 0.1s linear;
          .item {
            margin-top: 10px;
            margin-bottom: 10px;
            display: inline-block;
            user-select: none;
          }
          .selected {
            color: @green;
          }
          .mr30 {
            margin-right: 30px;
          }
          .mr50 {
            margin-right: 50px;
          }
        }
        .hidden {
          height: 30px;
          overflow: hidden;
        }
      }
    }
  }
  .recommend-list {
    margin: 20px -10px 0;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    .item {
      margin-right: 23px;
      border: 1px solid #f5f5f5;
      display: inline-block;
      width: 264px;
      opacity: 1;
      /* border-radius: 4px; */
      box-sizing: border-box;
      margin-bottom: 40px;
      &:hover {
        border: 1px solid rgba(#26c487, 0.9);
      }
      &:nth-child(5n) {
        margin-right: 0;
      }
      .handle-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        /* margin: 8px auto; */
        border-top: 1px solid #f5f5f5;
        box-sizing: border-box;
        .stepper-box {
          height: 100%;
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #f5f5f5;
        }
        .cart-box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: 36px;
          color: #00cd8a;
          @media screen and (max-width: 1280px) {
            font-size: 12px;
          }
          /* &:hover {
            border: 1px solid #00cd8a;
          } */
        }
      }
    }
  }
  .pagination {
    margin-bottom: 30px;
  }
}
</style>
