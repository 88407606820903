<!--
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-10 15:26:42
 * @LastEditors: booshaw
 * @LastEditTime: 2022-06-10 18:58:02
-->
<template>
  <div class="sort-wrapper">
    <div class="action">
      <div
        v-for="(item, index) in sortActions"
        :key="index"
        @click="handleSelect(item, index)"
        class="action-item"
        :class="currentAction === index ? 'active' : ''"
      >
        <span class="label">{{ item.name }}</span>
        <div class="toggle-box">
          <i
            class="el-icon-caret-top small"
            :class="item.selected === 0 ? 'un-selected' : 'selected'"
          ></i>
          <i
            class="el-icon-caret-bottom small"
            :class="item.selected === 1 ? 'un-selected' : 'selected'"
          ></i>
        </div>
      </div>
      <el-checkbox v-if="closestTimeFlag!='1'" v-model="stockFlag" @change="handleStockChange" style="margin-left:10px;">有货</el-checkbox>
    </div>
    <div class="result flex-end color-gray">
      <div class="mr20">
        共查询到
        <span class="fred">{{ total }}</span
        >件商品
      </div>
      <div class="mr10">
        <span class="gr">{{ pageSize }}</span>
        <span>/{{ totalPage }}</span>
      </div>
      <div class="color-black">
        <i
          @click="handlePreview"
          class="el-icon-arrow-left icon pre"
          :class="pageSize == 1 ? 'disabled' : totalPage < 2 ? 'disabled' : ''"
        ></i>
        <i
          @click="handleNext"
          class="el-icon-arrow-right icon next"
          :class="pageSize >= totalPage ? 'disabled' : ''"
        ></i>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number || String,
      default: 1
    },
    closestTimeFlag:{
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      sortActions: [
        {
          name: '销量',
          target: 'sellTotal',
          actions: ['desc','asc'],
          selected: 0
        },
        {
          name: '上架时间',
          target: 'createTime',
          actions: ['desc','asc'],
          selected: 0
        },
        {
          name: '价格',
          target: 'salePrice',
          actions: ['desc','asc'],
          selected: 0
        }
      ],
      currentAction: 0,
      stockFlag:true,
    }
  },
  computed: {
    totalPage() {
      return Math.ceil(this.total / 20)
    }
  },
  methods: {
    handleSelect(item, index) {
      item.selected = 1 - item.selected
      this.currentAction = index
      const sortOptions = `${item.target},${item.actions[item.selected]}`
      this.$emit('on-sort-change', sortOptions)
    },
    handlePreview() {
      if (this.pageSize < 2) return
      this.$emit('on-preview')
    },
    handleNext() {
      if (this.pageSize >= this.totalPage) return
      this.$emit('on-next')
    },
    handleStockChange(){
      if(this.closestTimeFlag == '1') this.stockFlag = 0
      this.$emit('on-stock-change', this.stockFlag)
    }
  }

}
</script>

<style scoped lang="less">
@import "../../../assets/base.less";
.sort-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background: #f7f8fa;
  border-radius: 0px 0px 0px 0px;
  padding-right: 14px;
  opacity: 1;
  border: 1px solid #f5f5f5;
  line-height: 40px;
  box-sizing: border-box;
  user-select: none;
  font-size: 14px;
  .action {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      border-left: 1px solid #efefef;
      box-sizing: border-box;
      color: #333333;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        border: 1px solid #00cd8a;
      }
      .toggle-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 4px;
        color: #adafad;
        .small {
          line-height: 0.6;
        }
        .selected {
          color: #00cd8a;
        }
      }
    }
    .active {
      background: #00cd8a;
      color: #ffffff;
      .selected {
        color: #ffffff;
      }
      .un-selected {
        color: #99ebd0;
      }
    }
  }
  .result {
    .icon {
      display: inline-block;
      cursor: pointer;
      color: #adafad;
      &:hover {
        color: #00cd8a;
      }
    }
    .pre {
      padding-right: 4px;
      padding-left: 4px;
    }
    .disabled {
      cursor: not-allowed;
    }
  }
}
</style>
