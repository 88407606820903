<!--
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-10 17:51:38
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-06-25 19:07:14
-->
<template>
  <div class="input-number-wrapper">
    <div class="number-box decrease" @click.stop="handleDecrease" :class="disabledDecrease ? 'disabled' : ''">
      <i class="el-icon-minus"></i>
    </div>
    <!-- @input="onInput" -->
    <input :value="value" type="number" :min="min"  :step="step" :stepStrictly="stepStrictly" @blur="onBlur" />
    <div class="number-box increase" @click="handleIncrease" :class="disabledIncrease ? 'disabled' : ''">
      <i  class="el-icon-plus"></i>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { log } from 'mathjs';
export default {
  props: {
    value: {
      type: Number | String,
      default: 1,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: Infinity
    },
    step: {
      type: Number,
      default: 1
    },
    stepStrictly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: this.min,
    };
  },
  watch: {
    value: {
      handler(value) {
        let newVal = value === undefined ? value : Number(value);
        newVal = this.judgeRanges(value)
        this.$emit('input', newVal)
      },
      immediate: true,
    },
  },
  computed: {
    disabledDecrease() {
      return this.inputValue <= (this.stepStrictly ? Math.max(this.step, this.min) : this.min)
    },
    disabledIncrease() {
      return (this.inputValue + (this.stepStrictly ? this.step : 0)) >= this.max
    }
  },
  methods: {
    onInput(e) {
      this.inputValue = Number(e.target.value);
      this.$emit("input", this.inputValue);
    },
    onBlur(e) {
      this.inputValue = this.judgeRanges(e.target.value)
      e.target.value = this.inputValue
      this.$emit('input', this.inputValue)
    },
    handleIncrease() {
      const next = this.inputValue + this.step * 1
      if (next > this.max) {
        // this.$message.warning('超过库存上限，请重新输入')
        return false
      }
      this.inputValue = next
      this.$emit('input', this.inputValue)
    },
    handleDecrease() {
      if (this.disabledDecrease) return
      this.inputValue = Math.max(this.min, Number(this.inputValue) - this.step * 1)
      this.$emit('input', this.inputValue)
    },
    judgeRanges(next) {
      if (next > this.max) {
        return this.stepStrictly ? this.max - this.max % this.step : this.max
      } else if (next <= this.step) {
        return this.stepStrictly ? Math.round(next / this.step) * this.step : this.step
      }else if(next>this.step){
        return this.stepStrictly ? Math.floor(next / this.step) * this.step : this.step
      }
      else {
        return next
      }
    },
  },
};
</script>

<style scoped lang="less">
::v-deep{
  .el-icon-plus{
    color: #999999;
    &:hover{
      color: #26C487;
    }
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.input-number-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  color: #333333;
  width: 100%;
  padding: 0 15px;
  .number-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 1px solid #f5f5f5;
  }
  .decrease {
    color: #333333;
  }
  .increase {
    color: #26c487;
  }
  .disabled {
    color: #999999;
    cursor: not-allowed;
  }
  input {
    flex-grow: 1;
    width: 30px;
    text-align: center;
  }
}
</style>
