<!--
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-09 19:03:41
 * @LastEditors: booshaw
 * @LastEditTime: 2022-06-10 09:48:39
-->
<template>
  <span class="tag-wrapper">
    <span>{{value}}</span>
    <i class="el-icon-close delete" @click="onClose"></i>
  </span>
</template>

<script type="text/ecmascript-6">
export default {
  name: 'custom-tag',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClose() {
      this.$emit('close', this.value)
    }
  }
}
</script>

<style scoped lang="less">
@import '../../../assets/base.less';
.tag-wrapper {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 6px;
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #f5f5f5;
  font-size: 14px;
  color: #333333;
  box-sizing: border-box;
  .delete {
    color: @red;
    font-size: 14px;
    padding-left: 8px;
    cursor: pointer;
  }
}
</style>
